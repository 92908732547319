<template>
  <tour :steps="tourSteps">
  <div class="content-wrapper">
    <page-header screenName="Aderir Campanha" :linkItems="linkItems" />

    <section class="p-2 card" v-if="loading">
      <b-row>
        <b-col cols="12" class="text-center">
          <b-spinner variant="custom" class="mb-1"></b-spinner>
        </b-col>
      </b-row>
    </section>
    <div id="adesao">
    <section class="p-2 card" v-if="!loading">
      <b-row class="titles">
        <b-col cols="12" class="mb-2">
          <h2>Adesão</h2>
        </b-col>

        <b-col cols="12" class="mb-2">
          <p>
            {{ moment().format("YYYY") }} -
            {{ getPurchaseData.chooseCampaign.campanha_descricao }}
          </p>
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="12">
          <div class="d-flex scroll-card">
            <div class="purchase-area">
              <Header :sesi-header="false" />

              <div v-if="!loading">
                <div
                  v-for="empresa in getEmpresasComputed"
                  :key="empresa.updatedAt"
                >
                  <Company
                      :empresa-prop="empresa"
                      :TipoSesi="false"
                  />
                </div>
              </div>
            </div>

            <ResumoVacina :IsCompany="true"/>
          </div>
        </b-col>
      </b-row>

      <b-row class="mt-4">
        <b-col cols="12">
          <div class="actions-area">
            <b-button
            v-if="!processandoValidacaoDosesPedido"
              variant="custom-blue"
              @click="validarDosesPedido"
              :disabled="getDesabilitarEnvio"
            >
              <feather-icon class="cursor-pointer" icon="CheckIcon" />
              &nbsp; Avançar
            </b-button>
            <b-button
              v-else
              variant="custom-blue"
              :disabled="true"
            >
              <feather-icon class="cursor-pointer" icon="CheckIcon" />
              &nbsp; Processando
            </b-button>

            <b-button variant="outline-danger" @click="descartarPedido">
              <feather-icon class="cursor-pointer" icon="Trash2Icon" />
              &nbsp; Descartar
            </b-button>
          </div>
        </b-col>
      </b-row>
    </section>
    </div>

    <ObservacaoModal />
    <NotificacoesModal />
  </div>
  </tour>
</template>

<script>
import moment from "moment";
import PageHeader from "@/views/components/custom/page-header/PageHeader.vue";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import vSelect from "vue-select";
import Header from "@/views/custom-pages/adesao/components/Header/Header.vue";
import Company from "@/views/custom-pages/adesao/components/Company/Company.vue";
import ResumoVacina from "@/views/custom-pages/adesao/components/ResumoVacina/ResumoVacina.vue";
import ObservacaoModal from "@/views/custom-pages/adesao/components/ObservacaoModal/ObservacaoModal.vue";
import NotificacoesModal from "@/views/custom-pages/adesao/components/NotificacoesModal/NotificacoesModal.vue";
import {
  AdesaoSetVacinas,
  AdesaoInit,
  AdesaoGetDesabilitarEnvio,
  AdesaoAdicionarEmpresa,
  AdesaoGetEmpresas,
  AdesaoValidarDosesPedido,
  AdesaoGetChooseCompany,
  AdesaoGetChooseCampaign,
  AdesaoDescartarPedido,
  AdesaoSetChooseOrder,
  AdesaoSetValidacaoDosesEmpresa,
  AdesaoGetInformacoesPersistencia
} from "../adesaoHelpers.js";
import purchaseMixin from "../../tour-mixins/purchaseTourMixin.js";

import {
  BRow,
  BCol,
  BForm,
  BBadge,
  BFormGroup,
  BButton,
  BIconTrashFill,
} from "bootstrap-vue";

export default {
  title: "Aderir Campanha",
  
  mixins: [purchaseMixin],
  components: {
    PageHeader,
    ValidationProvider,
    ValidationObserver,
    vSelect,
    BRow,
    BCol,
    BForm,
    BBadge,
    BFormGroup,
    BButton,
    BIconTrashFill,
    Header,
    Company,
    ResumoVacina,
    ObservacaoModal,
    NotificacoesModal,
  },

  data() {
    return {
      linkItems: [
        {
          name: "Adesão",
          routeName: "",
        },
        {
          name: "Aderir Campanha",
          active: true,
        },
      ],
      moment,
      loading: false,
      processandoValidacaoDosesPedido: false,
    };
  },

  mounted() {
    if(this.loadData){
      this.mountedInit()
    }
  },

  methods: {
    mountedInit(){
      AdesaoInit();
      AdesaoSetChooseOrder();
      AdesaoSetVacinas(AdesaoGetChooseCampaign(), AdesaoGetChooseCompany());
      AdesaoAdicionarEmpresa(AdesaoGetChooseCompany());
      AdesaoSetValidacaoDosesEmpresa(null);
    },
    descartarPedido() {
      AdesaoDescartarPedido(true);
    },
    validarDosesPedido() {
      this.processandoValidacaoDosesPedido = true
      AdesaoValidarDosesPedido(false, true);
    },
  },
  computed: {
    getPurchaseData() {
      const chooseCampaign = AdesaoGetChooseCampaign();
      const chooseCompany = AdesaoGetChooseCompany();

      if (!chooseCampaign || !chooseCompany) {
        return false;
      }

      return {
        chooseCampaign,
        chooseCompany,
      };
    },

    getEmpresasComputed() {
      return AdesaoGetEmpresas();
    },

    getDesabilitarEnvio() {
      return AdesaoGetDesabilitarEnvio();
    },
  },
  watch: {
    "$store.state.adesaoState.persistencia.status": {
      immediate: true,
      handler() {
        let persistencia = AdesaoGetInformacoesPersistencia();
        if (persistencia.status == null && this.processandoValidacaoDosesPedido) {
          this.processandoValidacaoDosesPedido = false
        }
      },
    },
  },
};
</script>

<style lang="scss">
@import "./style";
</style>