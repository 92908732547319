<template>
  <div v-if="getValidation" class="content-wrapper">
    <page-header screenName="Aderir Campanha" :linkItems="linkItems" />

    <section class="p-2 card" v-if="loading">
      <b-row>
        <b-col cols="12" class="text-center">
          <b-spinner variant="custom" class="mb-1"></b-spinner>
        </b-col>
      </b-row>
    </section>

    <section class="p-2 card" v-if="!loading">
      <b-row class="titles">
        <b-col cols="12" class="mb-2" v-if="!getDesabilitarDescartarAcoes">
          <b-badge pill variant="light-secondary"> Rascunho </b-badge>
        </b-col>

        <b-col cols="12" class="mb-2">
          <h2>
            Pedido <span v-if="!modoCriacao">{{ formataNumeroPedido }}</span>
          </h2>
        </b-col>
        <b-col cols="12" class="mb-2">
          <p>
            {{ moment().format("YYYY") }} - {{ getCampanhaDescricao }} |
            {{ getUnidadeDescricao }}
          </p>
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="12">
          <div class="d-flex scroll-card">
            <div class="purchase-area">
              <Header />

              <div v-if="!loading">
                <div v-if="getEmpresasComputed.length == 0" class="titles">
                  <p class="text-center font-weight-bold p-1">
                    <span v-if="modoCriacao"
                      >Adicione uma empresa clicando no botão abaixo.</span
                    >
                    <span v-else
                      >Não é possível salvar um pedido sem empresas.</span
                    >
                  </p>
                </div>
                <div v-else>
                  <draggable
                    v-model="sequencia"
                    tag="ul"
                    class="list-group list-group-flush"
                    :class="!modoVisualizacao ? 'cursor-move' : ''"
                    :options="{ disabled: modoVisualizacao }"
                  >
                    <div
                      v-for="empresa in getEmpresasComputed"
                      :key="empresa.updatedAt"
                    >
                      <Company
                        :EmpresaProp="empresa"
                        :TipoSesi="true"
                        :ModoCriacao="modoCriacao"
                        :ModoVisualizacao="modoVisualizacao"
                      />
                    </div>
                  </draggable>
                </div>
              </div>
            </div>

            <div v-if="!modoVisualizacao">
              <section class="p-2 card" v-if="getVacinasComputed.length == 0">
                <b-row>
                  <b-col cols="12" class="text-center">
                    <b-spinner variant="custom" class="mb-1"></b-spinner>
                  </b-col>
                </b-row>
              </section>
              <ResumoVacina :IsCompany="false" v-else />
            </div>
          </div>
        </b-col>
      </b-row>

      <b-row class="mt-4">
        <b-col cols="12" class="mb-4" v-if="modoCriacao">
          <span id="btn-adicionar-empresas">
            <button
              type="button"
              class="btn custom-outline-blue custom-blue-shadow"
              @click="showModal = true"
              :disabled="desabilitarBotaoAdicionarEmpresa"
            >
              <feather-icon class="cursor-pointer" icon="PlusCircleIcon" />
              &nbsp; Adicionar Empresa
            </button>
          </span>

          <!-- notificação botão desabilitado -->
          <span v-if="desabilitarBotaoAdicionarEmpresa">
            <b-tooltip target="#btn-adicionar-empresas"
              >Máximo 7 empresas.</b-tooltip
            >
          </span>
        </b-col>

        <b-col cols="12">
          <div class="actions-area" v-if="modoVisualizacao">
            <button
              type="submit"
              class="btn button-config btn-custom-blue"
              @click="descartarPedido"
              :disabled="desabilitarBotaoVoltar"
            >
              Voltar
            </button>
          </div>
          <div class="actions-area" v-else>
            <div
              id="cadastrar-pedido"
              v-if="modoCriacao"
              class="d-inline-block"
            >
              <b-button
                variant="custom-blue"
                :disabled="getDesabilitarEnvioRascunho"
                @click="salvarPedido(true)"
              >
                <feather-icon class="cursor-pointer" icon="SaveIcon" />
                &nbsp; Salvar Rascunho
              </b-button>
              <b-button
                variant="custom-blue"
                :disabled="getDesabilitarEnvio"
                @click="openModalConfirm"
              >
                <feather-icon class="cursor-pointer" icon="CheckIcon" />
                &nbsp; Lançar Pedidos
              </b-button>
            </div>

            <div id="editar-pedido" v-if="!modoCriacao" class="d-inline-block">
              <b-button
                variant="custom-blue"
                :disabled="getDesabilitarEnvioRascunho"
                @click="salvarPedidoRascunho(true)"
              >
                <feather-icon class="cursor-pointer" icon="SaveIcon" />
                &nbsp; Atualizar Rascunho
              </b-button>

              <b-button
                variant="custom-blue"
                :disabled="getDesabilitarEnvio"
                @click="salvarPedidoRascunho(false)"
              >
                <feather-icon class="cursor-pointer" icon="CheckIcon" />
                &nbsp; Lançar Pedido
              </b-button>
            </div>

            <b-button
              variant="outline-danger"
              @click="descartarPedido"
              class="d-inline-block"
              :disabled="getDesabilitarDescartarAcoes"
            >
              <feather-icon class="cursor-pointer" icon="Trash2Icon" />
              &nbsp; Descartar
            </b-button>
          </div>
        </b-col>
      </b-row>
    </section>

    <AdicionarEmpresaAdesaoModal
        v-if="showModal"
        :showModal="showModal"
        @fecharModal="fecharModal"
    />
    <ConfirmLancarPedidoModal
        :modalShow="modal.showModal"
        :typeModal="modal.typeModal"
        :modalName="'lancarRealizarNovoPedido'"
        @confirmed="salvarPedido(false)"
        @canceled="cancelPedido"
        @confirmedTrhowNewOrder="confirmedTrhowNewOrder"
    />
    <ObservacaoModal :ModoVisualizacao="modoVisualizacao" />
    <NotificacoesModal
      :previues="previues"
    />
  </div>
</template>

<script>
import PageHeader from "@/views/components/custom/page-header/PageHeader.vue";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import moment from "moment";
import vSelect from "vue-select";
import Header from "@/views/custom-pages/adesao/components/Header/Header.vue";
import Company from "@/views/custom-pages/adesao/components/Company/Company.vue";
import ResumoVacina from "@/views/custom-pages/adesao/components/ResumoVacina/ResumoVacina.vue";
import draggable from "vuedraggable";
import platform from "platform";
import $store from "@/store";
import ObservacaoModal from "@/views/custom-pages/adesao/components/ObservacaoModal/ObservacaoModal.vue";
import AdicionarEmpresaAdesaoModal from "@/views/custom-pages/adesao/components/AdicionarEmpresaAdesaoModal/AdicionarEmpresaAdesaoModal.vue";
import NotificacoesModal from "@/views/custom-pages/adesao/components/NotificacoesModal/NotificacoesModal.vue";
import ConfirmLancarPedidoModal from '@/views/components/custom/modals/ConfirmLancarPedidoModal.vue';


import {
  AdesaoInit,
  AdesaoSetVacinas,
  AdesaoGetChooseCampaign,
  AdesaoGetChooseUnit,
  AdesaoDescartarPedido,
  AdesaoSalvarPedido,
  AdesaoGetEmpresas,
  AdesaoGetVacinas,
  AdesaoGetDesabilitarEnvio,
  AdesaoAtualizarEmpresasSequencia,
  AdesaoGetChooseOrder,
  AdesaoSetChooseOrder,
  AdesaoSalvarPedidoRascunho,
  AdesaoGetResumoVacinas, AdesaoSetChooseCampaign, AdesaoSetChooseUnit,
} from "../adesaoHelpers.js";

import {
  BRow,
  BCol,
  BForm,
  BBadge,
  BFormGroup,
  BButton,
  BSpinner,
  BIconTrashFill,
  BModal,
  BTooltip,
} from "bootstrap-vue";

export default {
  title: "Aderir Campanha",

  components: {
    PageHeader,
    ValidationProvider,
    ValidationObserver,
    vSelect,
    BRow,
    BCol,
    BForm,
    BBadge,
    BFormGroup,
    BButton,
    BSpinner,
    BIconTrashFill,
    Header,
    Company,
    ResumoVacina,
    BModal,
    draggable,
    ObservacaoModal,
    AdicionarEmpresaAdesaoModal,
    NotificacoesModal,
    BTooltip,
    ConfirmLancarPedidoModal
  },

  created() {
    if (!this.getPurchaseData) {
      this.handleInvalidData();

      return false;
    }

    this.validation = true;
  },

  async mounted () {
    this.loading = true;
    this.modoCriacao = this.$route.name == "purchase";
    this.unit = AdesaoGetChooseUnit()
    this.campaign = AdesaoGetChooseCampaign()
    if (this.modoCriacao) {
      AdesaoInit(true);
      AdesaoSetChooseOrder(null);
      await AdesaoSetVacinas(AdesaoGetChooseCampaign(), AdesaoGetChooseUnit());
    } else {
      AdesaoSetChooseOrder(AdesaoGetChooseOrder());
      if (this.getChooseOrder.pedido_situacao) {
        this.modoVisualizacao =
          this.getChooseOrder.pedido_situacao == "Efetivada";
      }
    }
    this.loading = false;
  },

  data() {
    return {
      linkItems: [
        {
          name: "Adesão",
          routeName: "",
        },
        {
          name: "Aderir Campanha",
          active: true,
        },
      ],

      moment,

      loading: false,

      showModal: false,
      modoCriacao: true,
      modoVisualizacao: false,
      desabilitarBotaoVoltar: false,
      processoEmAndamento: false,
      modal: 
        {
          showModal: false,
          typeModal: 'success',
          typeButton: 'new-order'
        },

      termo: [],
      unit: null,
      campaign: null,
      previues: false,
    };
  },

  methods: {
    openModalConfirm() {
      this.modal.typeModal = 'warning';
      this.modal.showModal = true;
    },

    cancelPedido() {
      this.modal.showModal = false;
    },

    async confirmedTrhowNewOrder() {
      await this.salvarPedido(false)
      AdesaoSetChooseCampaign(this.campaign)
      AdesaoSetChooseUnit(this.unit)
      this.campaign = null
      this.unit = null
      this.previues = true
    },

    handleInvalidData() {
      this.$swal({
        icon: "warning",
        html: `Dados não encontrados.`,
        confirmButtonText: "Ok",
        customClass: {
          confirmButton: "custom-blue",
        },
      }).then((result) => {
        if (result.isConfirmed) {
          this.$router.replace({ name: "join-campaign" });
        }
      });
    },

    fecharModal() {
      this.showModal = false
    },

    descartarPedido() {
      this.desabilitarBotaoVoltar = true;
      AdesaoDescartarPedido(this.modoCriacao);
    },
    async salvarPedido(rascunho) {
      this.processoEmAndamento = true;

      this.setTermo();
      $store.dispatch("adesaoState/setTermo", this.termo);

      await AdesaoSalvarPedido(rascunho, false, false);
      this.modal.showModal = false;
    },
    salvarPedidoRascunho(rascunho) {
      AdesaoSalvarPedidoRascunho(rascunho);
    },

    setTermo() {
      const items = {
        so: platform.os.family,
        versao_so: platform.version,
      };
      return this.termo.push(items);
    },
  },

  computed: {
    getPurchaseData() {
      const chooseCampaign = AdesaoGetChooseCampaign();
      const chooseUnit = AdesaoGetChooseUnit();

      if (!chooseCampaign || !chooseUnit) {
        return false;
      }

      return {
        chooseCampaign,
        chooseUnit,
      };
    },

    getValidation() {
      return this.validation;
    },

    getEmpresasComputed() {
      return AdesaoGetEmpresas();
    },

    getVacinasComputed() {
      return AdesaoGetVacinas();
    },

    getDesabilitarEnvio() {
      return this.modoVisualizacao || AdesaoGetDesabilitarEnvio();
    },

    getDesabilitarEnvioRascunho() {
      return (
        this.modoVisualizacao ||
        AdesaoGetResumoVacinas().desabilitarEnvioRascunho ||
        this.processoEmAndamento
      );
    },

    getDesabilitarDescartarAcoes() {
      return this.modoVisualizacao;
    },

    sequencia: {
      get() {
        return AdesaoGetEmpresas();
      },
      set(empresas) {
        AdesaoAtualizarEmpresasSequencia(empresas);
      },
    },

    getChooseOrder() {
      return AdesaoGetChooseOrder();
    },

    getCampanhaDescricao() {
      return typeof this.getPurchaseData.chooseCampaign.campanha_descricao !=
        "undefined"
        ? this.getPurchaseData.chooseCampaign.campanha_descricao
        : this.getPurchaseData.chooseCampaign.descricao;
    },

    getUnidadeDescricao() {
      return typeof this.getPurchaseData.chooseUnit.descricao_unidade !=
        "undefined"
        ? this.getPurchaseData.chooseUnit.descricao_unidade
        : this.getPurchaseData.chooseUnit.descricao;
    },

    formataNumeroPedido() {
      if (this.getChooseOrder != null) {
        return "#" + String(this.getChooseOrder.numero_pedido).padStart(5, "0");
      }
    },
    desabilitarBotaoAdicionarEmpresa() {
      return AdesaoGetEmpresas().length >= 7;
    },
  },
  watch: {
    "$store.state.adesaoState.updatedAt": {
      immediate: true,
      handler() {
        //
      },
    },
  },
};
</script>

<style lang="scss">
@import "./style";

.company-card button:disabled {
  color: #d8d6de !important;
  cursor: not-allowed;
}
</style>