<template>
  <main class="container_addresses">
    <b-overlay :show="loading" rounded="lg" opacity="0.6" class="w-100">
      <b-row
          v-for="(address,index) in addressCompany"
          :key="index"
          class="container_address"
          :class="{'active': addressCompany.length === 1 && address_is_selected}"
          :id="address.id_empresa_endereco"
          @click="handleClick"
      >
        <b-tooltip
            v-if="existAddressWithAdhesion(address)"
            :target="'address-' + index"
            placement="left"
            triggers="hover"
        >
          Doses aderidas nesta campanha:
          <span v-html="getTextTooltipAddress(address)"></span>
        </b-tooltip>
        <b-col
            class="d-flex justify-content-center address"
            lg="12"
            md="12"
            sm="12"
            :id="'address-' + index"
        >
          <b-col lg="6" class="container-company-info">
            <div class="container-icon-by-company">
              <div>
                <feather-icon icon="MapPinIcon" class="icon-by-company" size="16" />
              </div>
            </div>
            <div>
              <div>
                <h4
                    :class="existAddressWithAdhesion(address) ? 'red-text' : ''"
                >
                  {{address.descricao}}
                </h4>
                {{address.logradouro}} nº {{address.numero}} {{address.nome}} - {{address.uf}}

              </div>
              <div>CEP: {{address.cep | VMask('#####-###')}}</div>
            </div>
          </b-col>

          <b-col lg="6" md="6" sm="6" class="ml-2">
            <h4>Unidade operacional</h4>
            <div>
               {{addressCompany[0].unidade ? addressCompany[0].unidade.descricao : ' - '}}
            </div>
          </b-col>

        </b-col>
        <div v-if="index == addressCompany.length" class="line-break"></div>
      </b-row>
      <div v-if="addressCompany.length > 0">
      </div>
    </b-overlay>
  </main>
</template>

<script>
import {BCol, BFormGroup, BOverlay, BRow, BTooltip} from "bootstrap-vue";
import vSelect from "vue-select"
export default {
    components: {
      BTooltip,
      BRow, BCol, BFormGroup, BOverlay,vSelect
    },
    directives: {
      'b-tooltip': BTooltip
    },
    props: {
      addressCompany: {
        required: true,
      },
      adesoes: {
        required: true,
      },
      prev: {
        required: false,
        default: false,
      },
    },

    data() {
      return {
        isLast: false,
        loading: false,
        address_is_selected: null
      }
    },

    methods: {
      handleClick(event) {
        if(!this.address_is_selected) {
          event.currentTarget.classList.add('active')
          this.address_is_selected = event.currentTarget
          const addressFiltered = this.filterAddress()
          addressFiltered[0].unidade = this.addressCompany[0].unidade
          this.$emit('addressFiltered', addressFiltered[0])
        }

        if(event.currentTarget.id != this.address_is_selected.id) {
          if(this.address_is_selected instanceof HTMLElement) {
            this.address_is_selected.classList.remove('active')
            this.address_is_selected = event.currentTarget
            event.currentTarget.classList.add('active')
          }
          this.address_is_selected = event.currentTarget
          const addressFiltered = this.filterAddress()
          addressFiltered[0].unidade = this.addressCompany[0].unidade
          this.$emit('addressFiltered', addressFiltered[0])

        }
      },

      getTextTooltipAddress(address) {
        if(this.adesoes) {
          return this.adesoes
              .filter((adesao) => adesao.id_empresa_endereco == address.id_empresa_endereco)
              .map((adesao) => `${adesao.quantidade} doses ${adesao.nome_tecnico}`)
              .join('<br>')
        }
      },

      existAddressWithAdhesion(address) {
        if(this.adesoes) {
          const existAddressWithAdhesion = this.adesoes.filter(adesao => adesao.id_empresa_endereco == address.id_empresa_endereco)
          return this.adesoes.length > 0 && existAddressWithAdhesion.length > 0
        }
      },

      filterAddress() {
        return this.addressCompany.filter(address => address.id_empresa_endereco == this.address_is_selected.id)
      },
    },
  }
</script>

<style>
.icon-by-company {
  color: #337ac3;
}

.container-company-info {
  display: flex;
}

.container-icon-by-company {
  width: 32px;
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  margin-right: 15px;
}

.container-icon-by-company > div {
  background-color: #2772C01F;
  padding: 10px;
  border-radius: 100px;
}

.line-break {
  height: 1px;
  background-color: #EBE9F1;
  margin-top: 22px;
}

.container_address {
  background-color: #fff;
  padding: 15px;
  border-bottom: 1px solid #D8D6DE;
  border-radius: 5px;
  cursor: pointer;
}

.container_address:last-child {
  border-bottom: none;
}

.container_content_company {
  display: flex;
  justify-content: space-between;
}

.container_addresses {
  max-height: 30vh;
  overflow-y: scroll;
  overflow-x: hidden;
  border: 2px solid #D8D6DE;
  padding: 5px;
  border-radius: 5px;
}

.container_addresses .container_address.active {
  background-color: #f7f7f8;
}

.container_addresses::-webkit-scrollbar-thumb {
  background: #cad0d7;
  border-radius: 6px;
}

.container_addresses::-webkit-scrollbar {
  width: 6px;
}

.red-text {
  color: red
}

</style>