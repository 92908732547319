<template>
  <div class="container_companies">
    <b-overlay :show="loading" rounded="lg" opacity="0.6" class="w-100">
      <div
          class="container_company"
          :class="{'active': companies.length === 1 && company_is_selected}"
          :id="company.id_empresa"
          @click="handleClick"
          v-for="company in companies"
          :key="company.documento"
      >
        <div class="company_title">
          <h4>{{company.nome_empresa}}</h4>
        </div>
        <div class="container_content_company">
          <div>
            <span>{{ company.documento.length === 14 ? 'CNPJ' : 'CPF' }}: {{ formatDocument(company.documento) }}</span>
          </div>
        </div>
      </div>
    </b-overlay>
  </div>
</template>

<script>
import {BCol, BFormInput, BRow, BForm, BButton, BOverlay} from "bootstrap-vue";
  export default {
    name: 'CompanyList',
    props: {
      companies: {
        required: true,
        type: Array|null,
      },
    },
    components: {
      BOverlay,
      BCol, BFormInput,BRow,BForm,BButton
    },

    data() {
      return {
        loading: false,
        next: null,
        company_is_selected: null,
      }
    },

    methods: {
      handleClick(event) {

        if(!this.company_is_selected) {
          event.currentTarget.classList.add('active')
          this.company_selected = event.currentTarget
          this.company_is_selected = true
          const companyFiltered = this.companies.filter(company => company.id_empresa == this.company_selected.id)
          this.$emit('companySelected', companyFiltered)
        }

        if(event.currentTarget.id != this.company_selected.id) {
          if(this.company_selected instanceof HTMLElement) {
            this.company_selected.classList.remove('active')
            this.company_selected = event.currentTarget
            event.currentTarget.classList.add('active')
          }
          this.company_selected = event.currentTarget
          const companyFiltered = this.companies.filter(company => company.id_empresa == this.company_selected.id)

          this.$emit('companySelected', companyFiltered)

        }
      },

      formatDocument(document) {
        if (document.length === 14) {
          return this.$options.filters.VMask(document, '##.###.###/####-##');
        } else if (document.length === 11) {
          return this.$options.filters.VMask(document, '###.###.###-##');
        } else {
          return document;
        }
      },
    },
  }

</script>

<style>
.container_company {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  padding: 15px;
  border-bottom: 1px solid #D8D6DE;
  border-radius: 5px;
  cursor: pointer;
}

.container_company:last-child {
  border-bottom: none;
}

.container_content_company {
  display: flex;
  justify-content: space-between;
}

.container_companies {
  max-height: 30vh;
  overflow-y: scroll;
  border: 2px solid #D8D6DE;
  padding: 5px;
  border-radius: 5px;
}

.container_companies .container_company.active {
  background-color: #f7f7f8;
}

.cidade {
  text-align: left;
  width: 200px;
}

.company_title {
  color: #6E6B7B;
  margin-bottom: 5px;
}

.container_companies::-webkit-scrollbar-thumb {
  background: #cad0d7;
  border-radius: 6px;
}

.container_companies::-webkit-scrollbar {
  width: 6px;
}
</style>